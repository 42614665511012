import { RouterProvider as RouterProviderReactDom } from 'react-router-dom';
import { privateRouter, publicRouter } from '@routes';
import { appStore, routerLoginSelector, useShallow } from '@appStore';

const RouterProvider = () => {
  const isUserLogin = appStore(useShallow(routerLoginSelector));
  // Get the auth token from the context
  return (
    <RouterProviderReactDom
      router={isUserLogin ? privateRouter : publicRouter}
    />
  );
};

export default RouterProvider;
