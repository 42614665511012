export enum Environment {
  PRODUCTION = 'prod',
  STAGING = 'staging',
  DEVELOPMENT = 'dev',
  LOCAL = 'local',
}

export type EnvironmentState = {
  environment: Environment;
};

export interface EnvironmentSliceActions {
  /**
   * Set the environment
   * @param environment - The environment to set
   * @returns void
   */
  setEnvironment: (environment: Environment) => void;
}

export type EnvironmentSlice = EnvironmentState & EnvironmentSliceActions;
