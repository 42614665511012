import React from 'react';
import {
  LocalizationProvider,
  ModalProvider,
  RouterProvider,
  ThemeProvider,
} from '@providers';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 5,
      retryDelay: 1000,
    },
  },
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <React.StrictMode>
        <ThemeProvider>
          <ModalProvider>
            <LocalizationProvider>
              <RouterProvider />
            </LocalizationProvider>
          </ModalProvider>
        </ThemeProvider>
      </React.StrictMode>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

export default App;
