import {
  Navigate,
  Route,
  createBrowserRouter,
  createRoutesFromChildren,
} from 'react-router-dom';
import {
  LazyDashboard,
  LazyLogs,
  LazyPrivateHost,
  LazySettings,
} from './Screens';

export const privateRouter = createBrowserRouter(
  createRoutesFromChildren(
    <Route path="/" element={<LazyPrivateHost />}>
      <Route path="" element={<Navigate to="dashboard" replace />} />
      <Route path="dashboard" element={<LazyDashboard />} />
      <Route path="settings" element={<LazySettings />} />
      <Route path="logs" element={<LazyLogs />} />
      <Route path="*" element={<Navigate to="dashboard" replace />} />
    </Route>,
  ),
);
