import { Button, Text } from '@chakra-ui/react';
import { NavigationButtonProps } from './types';
import { NavLink } from 'react-router-dom';

const NavigationButton = ({
  size = 'md',
  title,
  to,
  isAuthButton,
}: NavigationButtonProps) => {
  const value = isAuthButton === false ? 'outline' : 'solid';
  return (
    <Button
      size={size}
      as={NavLink}
      to={to}
      variant={isAuthButton === undefined ? 'Ghost' : value}
    >
      <Text>{title}</Text>
    </Button>
  );
};

export default NavigationButton;
