import { LazyProvider } from '@providers';
import { lazy } from 'react';

// [Start]-------- Host screen --------

const Host = lazy(() => import('@screens/private/Host/Host'));

export const LazyPrivateHost = () => (
  <LazyProvider>
    <Host />
  </LazyProvider>
);

// [End]-------- Host screen --------

// [Start]-------- Screens --------

const Dashboard = lazy(() => import('@screens/private/Dashboard/Dashboard'));

export const LazyDashboard = () => (
  <LazyProvider>
    <Dashboard />
  </LazyProvider>
);

const Settings = lazy(() => import('@screens/private/Settings/Settings'));

export const LazySettings = () => (
  <LazyProvider>
    <Settings />
  </LazyProvider>
);

const Logs = lazy(() => import('@screens/private/Logs/Logs'));

export const LazyLogs = () => (
  <LazyProvider>
    <Logs />
  </LazyProvider>
);

// [End]-------- Screens --------
