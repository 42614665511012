import { IconMoon, IconSun } from '@assets/icons';
import { IconButton, useColorMode } from '@chakra-ui/react';

const ThemeIcon = () => {
  const { toggleColorMode, colorMode } = useColorMode();
  return (
    <IconButton
      aria-label="theme-toggle"
      icon={
        colorMode === 'dark' ? (
          <IconMoon height={'1.5em'} width={'1.5em'} />
        ) : (
          <IconSun height={'1.5em'} width={'1.5em'} />
        )
      }
      variant="outline"
      onClick={toggleColorMode}
    />
  );
};

export default ThemeIcon;
