import { AppStoreSlice } from '../../appStore';
import { UserSlice, UserState } from './types';

const initialState: UserState = {
  name: '',
  email: '',
  token: '',
};

const createUserSlice: AppStoreSlice<UserSlice> = (set) => ({
  ...initialState,
  setUserData: (userState) => {
    set((state) => {
      state.user.email = userState.email;
      state.user.name = userState.name;
      state.user.token = userState.token;
    });
  },
  logOut: () => {
    set((state) => {
      state.user.email = '';
      state.user.name = '';
      state.user.token = '';
    });
  },
});

export default createUserSlice;
