import {
  Navigate,
  Route,
  createBrowserRouter,
  createRoutesFromChildren,
} from 'react-router-dom';
import {
  LazyAboutUs,
  LazyDocumentation,
  LazyForgotPassword,
  LazyLanding,
  LazyLogin,
  LazyPrivacyPolicy,
  LazyPublicHost,
  LazyRedirectRegister,
  LazyRegister,
  LazyResetPassword,
} from './screens';

const AuthRoutes: React.ReactNode = (
  <>
    <Route path="login" element={<LazyLogin />} />
    <Route path="register" element={<LazyRegister />} />
    <Route path="forgot-password" element={<LazyForgotPassword />} />
    <Route path="reset-password" element={<LazyResetPassword />} />
    <Route path="redirect-register" element={<LazyRedirectRegister />} />
  </>
);

const StaticRoutes: React.ReactNode = (
  <>
    <Route path="privacy-policy" element={<LazyPrivacyPolicy />} />
    <Route path="about-us" element={<LazyAboutUs />} />
    <Route path="contact-us" element={<LazyAboutUs />} />
  </>
);

const DocumentationRoutes: React.ReactNode = (
  <>
    <Route path="docs" element={<LazyDocumentation />} />
  </>
);

export const publicRouter = createBrowserRouter(
  createRoutesFromChildren(
    <Route path="/" element={<LazyPublicHost />}>
      <Route path="" element={<LazyLanding />} />
      {AuthRoutes}
      {DocumentationRoutes}
      {StaticRoutes}
      <Route path="*" element={<Navigate to="/" replace />} />
    </Route>,
  ),
);
