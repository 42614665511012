import { LazyProvider } from '@providers';
import { lazy } from 'react';

// [Start]-------- Host screen --------

const Host = lazy(() => import('@screens/public/Host/Host'));

export const LazyPublicHost = () => (
  <LazyProvider>
    <Host />
  </LazyProvider>
);

// [End]-------- Host screen --------

// [Start]-------- Landing screens --------

const Landing = lazy(() => import('@screens/public/landingPage/LandingPage'));

export const LazyLanding = () => (
  <LazyProvider>
    <Landing />
  </LazyProvider>
);

// [Start]-------- Static screens --------
const PrivacyPolicy = lazy(
  () => import('@screens/public/static/PrivacyPolicy/PrivacyPolicy'),
);

export const LazyPrivacyPolicy = () => (
  <LazyProvider>
    <PrivacyPolicy />
  </LazyProvider>
);

const AboutUs = lazy(() => import('@screens/public/static/AboutUs/AboutUs'));

export const LazyAboutUs = () => (
  <LazyProvider>
    <AboutUs />
  </LazyProvider>
);

const ContactUs = lazy(
  () => import('@screens/public/static/ContactUs/ContactUs'),
);

export const LazyContactUs = () => (
  <LazyProvider>
    <ContactUs />
  </LazyProvider>
);

// [End]-------- Static screens --------

// [Start]-------- Auth screens --------

const Login = lazy(() => import('@screens/public/authentication/Login/Login'));

export const LazyLogin = () => (
  <LazyProvider>
    <Login />
  </LazyProvider>
);

const Register = lazy(
  () => import('@screens/public/authentication/Register/Register'),
);

export const LazyRegister = () => (
  <LazyProvider>
    <Register />
  </LazyProvider>
);

const ForgotPassword = lazy(
  () => import('@screens/public/authentication/ForgotPassword/ForgotPassword'),
);

export const LazyForgotPassword = () => (
  <LazyProvider>
    <ForgotPassword />
  </LazyProvider>
);

const ResetPassword = lazy(
  () => import('@screens/public/authentication/ResetPassword/ResetPassword'),
);

export const LazyResetPassword = () => (
  <LazyProvider>
    <ResetPassword />
  </LazyProvider>
);

const RedirectRegister = lazy(
  () =>
    import('@screens/public/authentication/RegisterRedirect/RegisterRedirect'),
);

export const LazyRedirectRegister = () => (
  <LazyProvider>
    <RedirectRegister />
  </LazyProvider>
);

// [End]-------- Auth screens --------

// [Start]-------- Documentation screens --------

const Documentation = lazy(
  () => import('@screens/public/documentation/Documentation'),
);

export const LazyDocumentation = () => (
  <LazyProvider>
    <Documentation />
  </LazyProvider>
);

// [End]-------- Documentation screens --------
