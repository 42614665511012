import { AppStoreSlice } from '../../appStore';
import { Environment, EnvironmentSlice, EnvironmentState } from './types';

const initialState: EnvironmentState = {
  environment: Environment.LOCAL,
};

const createEnvironmentSlice: AppStoreSlice<EnvironmentSlice> = (set) => ({
  ...initialState,
  environment: Environment.LOCAL,
  setEnvironment: (newEnv) =>
    set((state) => {
      state.env.environment = newEnv;
    }),
});

export default createEnvironmentSlice;
